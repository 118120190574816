import React from "react"

import classNames from "classnames"
import DefaultLayout from "../../layout/default"

import CtaForm from "../../pagesSections/index/CtaForm"

import pageStyles from "../../styles/_V2/pagesStyles/styles.module.scss"
import styles from "../styles/_V2/docs.module.scss"
import AdaptiveLink from "../../components/AdaptiveLink"
import {pagesLinks} from "../../pages-data/common/links"

export default function PagePrivacyPolicy() {
    const metaTags = [
        // {
        //   name: 'keywords',
        //   content: 'Автоматизация ресторана, автоматизация кафе, автоматизация бара, автоматизация столовой, ipad pos, касса на ipad, калькуляция, расчет себестоимости, технологическая карта, российская система автоматизации',
        // },
        {
            name: "description",
            content: "Лицензионный договор является офертой ООО «Квик Ресто» — система автоматизации предприятий общественного питания Quick Resto — программа для полноценного складского учёта в общепите онлайн, удобный кассовый терминал на планшете, CRM и настраиваемая система отчетов.",
        },
        {
            property: "og:url",
            content: "https://quickresto.ru/licenseAgreement/annex4",
        },
        {
            property: "og:title",
            content: "Лицензионный договор на право использования системы автоматизации Quick Resto",
        },
        {
            property: "og:description",
            content: "Лицензионный договор является офертой ООО «Квик Ресто» — система автоматизации предприятий общественного питания Quick Resto — программа для полноценного складского учёта в общепите онлайн, удобный кассовый терминал на планшете, CRM и настраиваемая система отчетов.",
        },
        {
            property: "og:type",
            content: "website",
        },
    ]

    return (
        <DefaultLayout
            title="Приложение № 4"
            metaTags={metaTags}
            mainWrapperClassName={classNames(
                pageStyles.pageWrapper,
                pageStyles.pageGray,
                styles.docs,
            )}
            linkCanonical="https://quickresto.ru/licenseAgreement/annex4/"
            footerClassName={pageStyles.pageSection}
        >
            <section
                className={classNames(styles.docs__container, pageStyles.pageSection)}
            >
                <div className={styles.docs__content}>
                    <h1 className={styles.bigMb}>
                        Специальные условия Лицензии на Опциональную программу - «Телеграм-Бот»
                        входящую в пакет Опциональных программ для ЭВМ «Лояльность.360»
                    </h1>

                    <div>
                        <p>
                            Приложение № 4
                            к Лицензионному договору на право использования
                            программы для ЭВМ «Система автоматизации предприятий
                            общественного питания «Quick Resto»» и иных
                            программных продуктов ООО «Квик Ресто»
                        </p>

                    </div>

                    <div>
                        <p/>

                        <p>1. Настоящее Приложение, как и Лицензионный договор, является
                            офертой <strong>ООО</strong><strong>&laquo;Квик Ресто&raquo;</strong>&nbsp;<br/> (ОГРН
                            5137746099046, ИНН&nbsp;7726734798, 125167 г. Москва, Ленинградский пр. 36, стр. 30, пом.
                            96), именуемого в дальнейшем Лицензиар, адресованной юридическому лицу или индивидуальному
                            предпринимателю, именуемому в дальнейшем &laquo;Лицензиат&raquo; заключить лицензионное
                            соглашение в соответствии с п. 2 ст. 437 Гражданского кодекса Российской Федерации.</p>
                        <p>2. Под офертой согласно настоящему Приложению № 4 подразумевается предложение Лицензиара
                            адресованное, <u>имеющему лицензию на основную Программу Лицензиату</u>, приобрести за
                            дополнительное вознаграждение Пакет опциональных дополнительных программных функциональных
                            модулей &laquo;Лояльность 360&raquo; для Программы ЭВМ Лицензиара в частности входящий в
                            указанный пакет опциональный дополнительный программный функциональный
                            модуль &laquo;Телеграм-бот&raquo; на основании простой (неисключительной) лицензии на
                            результат интеллектуальной деятельности ООО &laquo;Квик Ресто&raquo; &ndash; разработанный
                            Лицензиаром опциональный дополнительный программный функциональный
                            модуль &laquo;Телеграм-бот&raquo;</p>
                        <p>Сервис &laquo;Телеграм-бот&raquo; позволяет Лицензиату на основе макета, имеющегося в
                            Сервисе &laquo;Телеграм-бот&raquo;, самостоятельно формировать (наполнять содержимым) и
                            редактировать свой сайт в сети интернет, в том числе создавать на своем сайте электронное
                            меню предлагаемых Лицензиатом блюд и напитков с указанием их названия, описания состава и
                            стоимости, отображать текущие акции в заведении с указанием описания, периода действия.
                            Также сервис &laquo;Телеграм-бот&raquo; позволяет Лицензиату оформлять доставку блюд и
                            напитков в соответствии с их заказами, сформированными дистанционным способом на основании
                            электронного меню на сайте Лицензиата.</p>
                        <p>Простая (неисключительная) лицензия на результат интеллектуальной деятельности
                            ООО &laquo;Квик Ресто&raquo; &ndash; разработанный Лицензиаром опциональный дополнительный
                            программный функциональный модуль &laquo;Телеграм-бот&raquo; реализуется исключительно в
                            пакете опциональных дополнительных программных функциональных модулей для
                            ЭВМ &laquo;Лояльность 360&raquo; и исключительно пользователям имеющим Лицензию на основную
                            программу</p>
                        <p>Акцепт на указанную в п.2 настоящего Приложения оферту возможен при одновременном соблюдении
                            3 условий:</p>
                        <p>1) Лицензиат приобретающий Лицензию на &laquo;Телеграм-бот&raquo; в пакете опциональных
                            дополнительных программных функциональных модулей &laquo;Лояльность 360&raquo; должен иметь
                            Лицензию на основной программный продукт (Программу) Лицензиара</p>
                        <p>2) Лицензиат приобретает Лицензию на &laquo;Телеграм-бот&raquo; в Пакете в пакете
                            опциональных дополнительных программных функциональных модулей &laquo;Лояльность
                            360&raquo; со всеми иными входящими в указанный Пакет опциональными программами.</p>
                        <p>3) Оплата вознаграждения за пакет опциональных программ для ЭВМ &laquo;Лояльность
                            360&raquo; по счету, выставленному Лицензиаром Лицензиату в соответствии с Тарифным планом,
                            выбранным Лицензиатом</p>
                        <p>При не соблюдении любого из вышеуказанных пунктов &ndash; оферта не считается
                            акцептованной</p>
                        <p><br/> 3. Лицензиар дополнительно к самостоятельному оформлению указанному в п.2, в
                            соответствии с требованиями Лицензиата визуально адаптирует &laquo;Телеграм-бот&raquo; под
                            его деятельность</p>
                        <p>Под адаптацией &laquo;Телеграм-бота&raquo; под деятельность Лицензиата подразумевается
                            исключительно визуальное адаптирование, например: визуальное воспроизведение в Телеграм-боте
                            наименования (фирменного наименования) Лицензиата, его товарных знаков и/или знаков
                            обслуживания, логотипов, других изображений и текстов, политику конфиденциальности и иной
                            необходимой информации о Лицензиате.</p>
                        <p>Адаптация, &laquo;Телеграм-бота&raquo; под деятельность Лицензиата осуществляется
                            Лицензиаром, после осуществления Лицензиатом оплаты лицензионного вознаграждения за
                            использование пакета опциональных дополнительных программных функциональных
                            модулей &laquo;Лояльность 360&raquo; и<u><strong>после предоставления всех необходимых
                                материалов и информации.</strong></u></p>
                        <p>Срок на адаптацию не должен превышать трех месяцев с момента предоставления Лицензиатом всех
                            необходимых материалов. Срок на адаптацию &laquo;Телеграм-бота&raquo; может быть продлен в
                            случае, если необходимые документы не были предоставлены в оговоренные сроки или по иным
                            причинам независящим от Лицензиара.</p>
                        <p>Указанная адаптация не предусматривает изменение функционала (возможностей) Телеграм-бота, в
                            связи с чем Телеграм-бот является универсальным программным продуктом и предоставляется
                            пользователям (Лицензиатам) в том виде &laquo;как есть&raquo;, за исключением Визуальной
                            адаптации, указанной в настоящем пункте Приложения № 4.</p>
                        <p>4. Сервис &laquo;Телеграм-бот&raquo; является универсальным программным продуктом и
                            предоставляется Лицензиатам в том виде &laquo;как есть&raquo;. Лицензиар не участвует в
                            формировании (наполнении содержимым) и редактировании телеграм-бота Лицензиара в сети
                            интернет, указанные действия выполняются Лицензиатом самостоятельно.</p>
                        <p>5. Лицензиар гарантирует Лицензиату, что он является законным правообладателем опционального
                            дополнительного программного функционального модуля - &laquo;Телеграм-бот&raquo;, в силу его
                            создания по средством открытого исходного кода <span lang="en-US">Telegram</span></p>
                        <p>6. Срок действия Лицензии на Пакет опциональных дополнительных программных функциональных
                            модулей &laquo;Лояльность 360&raquo; в частности входящий в указанный пакет опциональный
                            дополнительный программный функциональный модуль &laquo;
                            <strong>Телеграм-Бот&raquo;</strong> и срок доступа к опциональному дополнительному
                            программному модулю &laquo;<strong>Телеграм-Бот&raquo; </strong>входящий вПакет опциональных
                            дополнительных программных функциональных модулей &laquo;Лояльность 360&raquo;, определяются
                            в соответствии с Разделом 2 и Разделом 4 Лицензионного договора на право использования
                            программы для ЭВМ &laquo;Система автоматизации предприятий общественного
                            питания &laquo;Quick Resto&raquo; и иных программных продуктов ООО &laquo;Квик Ресто&raquo;.
                        </p>
                        <p>По итогам периода использования прав на Пакет опциональных дополнительных программных
                            функциональных модулей &laquo;Лояльность 360&raquo; в частности входящий в указанный пакет
                            Опциональный дополнительный программный функциональный модуль &laquo;
                            <strong>Телеграм-Бот&raquo;</strong> Лицензиар направляет на электронную почту, указанную
                            Лицензиатом, акт исполнения обязательств по настоящему Договору. При отсутствии у Лицензиата
                            в течение 3 (трех) календарных дней с момента направления ему данного акта и отсутствия
                            мотивированных возражений, данный акт считается согласованным и подписанным Лицензиатом, а
                            услуги принятыми без претензий к качеству и срокам.</p>
                        <p>7. Продление Лицензии на Пакет опциональных дополнительных программных функциональных
                            модулей &laquo;Лояльность 360&raquo; в частности входящий в указанный пакет опциональный
                            дополнительный программный функциональный модуль &laquo;
                            <strong>Телеграм-Бот&raquo;</strong> и оплата вознаграждения за предоставление Лицензии на
                            Пакет опциональных дополнительных программных функциональных модулей &laquo;Лояльность
                            360&raquo; в частности входящий в указанный пакет опциональный дополнительный программный
                            функциональный модуль &laquo;<strong>Телеграм-Бот&raquo; </strong>осуществляется на условиях
                            определяются в соответствии с Разделом 2 и Разделом 4 Лицензионного договора на право
                            использования программы для ЭВМ &laquo;Система автоматизации предприятий общественного
                            питания &laquo;Quick Resto&raquo; и иных программных продуктов ООО &laquo;Квик Ресто&raquo;
                        </p>
                        <p>8. Сервис &laquo;Телеграм-бот&raquo; размещается в интернет на платформе мессенджера
                            Telegram: в формате&nbsp;<u><a
                                href="https://xn--_-7sbabhdoedbneki5dej93a.menuquickresto.ru/?roistat_visit=2080297">https://</a><a
                                href="http://t.me/%D0%B8%D0%BC%D1%8F_%D0%B1%D0%BE%D1%82%D0%B0_bot">t.me/имя_бота_bot</a></u>&nbsp;,
                            где имя бота предоставляется клиентом, либо используется существующий бот клиента при
                            передачи прав на бот на аккаунт Лицензиара для настройки и обслуживания бота на срок
                            действия Лицензии.</p>
                        <p>9. Во всем ином, не предусмотренном настоящим Приложением № 4, применяются положения
                            Лицензионного договора, а также положения действующего законодательства Российской
                            Федерации.</p>
                        <p>10. Краткое описание Сервиса &laquo;Телеграм-бот&raquo; и возможностей данной программы, а
                            также преимуществ содержится по следующей интернет ссылке: <u><a
                                href="https://quickresto.ru/wl/">https://quickresto.ru/wl/</a></u></p>
                        <p>11. Лицензиат подтверждает, что он:</p>
                        <p>&middot;&nbsp;перед заключением настоящего Приложения № 4 в полном объеме ознакомлен с
                            функционалом (возможностями) Сервиса &laquo;Телеграм-бот&raquo;;</p>
                        <p>&middot; перед заключением настоящего Приложения № 4 в полном объеме ознакомлен с текстом
                            Лицензионного договора на право использования программы для ЭВМ &laquo;Система автоматизации
                            предприятий общественного питания &laquo;Quick Resto&raquo;&raquo; и иных программных
                            продуктов ООО &laquo;Квик Ресто&raquo;, в котором содержатся все основные условия
                            предоставления лицензий на программные продукты ООО &laquo;Квик Ресто&raquo; и который
                            содержится по следующей интернет ссылке: <u><a
                                href="https://quickresto.ru/licenseAgreement/">https://quickresto.ru/licenseAgreement/</a></u>;
                        </p>
                        <p>- соглашается с тем, что:</p>
                        <p>С 01.09.2024 г. новые Лицензиаты (новые клиенты) не имеют возможность приобрести лицензию на
                            пакет опциональных дополнительных программных функциональных модулей &laquo;Лояльность
                            360&raquo; без приобретения основной Программы Лицензиара.</p>
                        <p>Действующие Лицензиаты, имеющие оплаченные/приобретенные Лицензии на опциональные
                            дополнительные программные функциональные модули на 01.09.2024 г. (до введения
                            пакета &laquo;Лояльность 360&raquo;) пользуются лицензиями до момента окончания своих
                            действующих лицензий на опциональные дополнительные программные функциональные модули, после
                            окончания срока таких лицензий возможность приобрести/продлить опциональные дополнительные
                            программные функциональные модули без покупки основной Программы не допускается.</p>
                        <p>- Уведомлен об отсутствии, в связи с изменением тарифной политики, возможности приобрести
                            дополнительный опциональный программный функциональный модуль &laquo;
                            <strong>Телеграм-Бот&raquo; </strong>отдельно отПакета Опциональных дополнительных
                            программных функциональных модулей &laquo;Лояльность 360&raquo;,</p>
                        <p>- Предупрежден о том, что без приобретения Лицензии на основную Программу/в случае окончания
                            лицензии на основную Программу, опциональные дополнительные программные функциональные
                            модули будут работать в ограниченном режиме, с ограниченным функционалом/нестабильно и
                            Лицензиар не несет ответственности за такие действия, так как это не зависит от действий
                            Лицензиара, а связано с техническими особенностями Программы и Опциональных дополнительных
                            программных функциональных модулей</p>
                        <p>- осознает, что Программа является основным продуктом Лицензиара, без которого использование
                            любого опционального дополнительного модуля из Пакета &laquo;Лояльность 360&raquo; в полном
                            объеме будет функционально ограничено, в виду того, что модули являются вторичными
                            (дополнительными) к Программе и внедряются в основную Программу, позволяя расширить ее
                            функционал, а именно в панель управления Программой. В отсутствие приобретенной лицензии на
                            основную Программу, основные разделы в панели не будут доступны/будут работать нестабильно,
                            что повлияет на работу опциональных дополнительных программных функциональных модулей.</p>
                        <p>&middot;&nbsp;осознаёт, что &laquo;Телеграм-бот&raquo; является универсальным программным
                            продуктом и предоставляется пользователям (Лицензиатам) в том виде &laquo;как есть&raquo;,
                            за следующим исключением: Сервиса &laquo;Телеграм-бот&raquo; визуально адаптируется под
                            деятельность Лицензиата, без изменения функционала Сервиса &laquo;Телеграм-бот&raquo;, иные
                            возможные адаптации оцениваются человеко-часами и оплачиваются дополнительно;</p>
                        <p>- Лицензиар не несёт ответственность за удаление или ограничение доступа
                            к &laquo;Телеграм-бот&raquo; по независящим от него причинам (Санкции, размещения
                            Лицензиатом материалов нарушающих правила и ограничения установленные платформой).</p>
                        <p>- осознает, что при неуплате продления Лицензии на пакет опциональных дополнительных
                            программных функциональных модулей &laquo;Лояльность 360&raquo; куда
                            входит &laquo;Телеграм-бот&raquo;, Лицензиар вправе отключить, а затем
                            удалить &laquo;Телеграм-бот&raquo; Лицензиата и отключить функционал настройки в облачном
                            бэк-офисе Лицензиата;</p>
                        <p>- заключает настоящее Приложение № 4, так как убедился в том, что
                            Сервис &laquo;Телеграм-бот&raquo; в полной мере соответствует потребностям и ожиданиям
                            Лицензиата.</p>
                        <p>- В случае необходимости Самостоятельного несет расходы на авторизацию
                            пользователей &laquo;Телеграм-бот&raquo; на основании п. 13.4 Лицензионного соглашения.</p>
                        <p>- Не несет расходы на хранение данных Лицензиата, а также техническую
                            поддержку &laquo;Телеграм-бот&raquo;, исключительно на период действия оплаченной
                            Лицензии.</p>
                    </div>
                </div>
            </section>

            <CtaForm
                isFry={false}
                className={pageStyles.pageSection}
                isWhiteBackground
            />
        </DefaultLayout>
    )
}
